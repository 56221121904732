<template>
  <v-container>
    <v-row>
      <h4 class="System_admin_text_Product ml-2">
        CALCULATING ADJUSTED CASH FLOW (Seller's Discretionary Income / SDE)
      </h4>
    </v-row>

    <v-row id="Deal">
      <v-col cols="12" sm="3">
        <p>
          <span
            ><b>DEAL: </b>
            <span
              ><b>{{ this.currentDeal }}</b></span
            ></span
          >
        </p>
      </v-col>
    </v-row>

    <v-row id="Button Group">
      <v-col cols="9">
        <div class="text-right">
          <b-button-group>
            <!-- <button class="offers-button" @click="openOffers">Offers</button>
            <button class="tasks-button" @click="openTasks">Tasks</button> -->
            <b-button class="uploads-button" @click="openUploads"
              >Uploads</b-button
            >
            <b-button class="save-button" @click="saveFormData">Save</b-button>
            <b-button class="back-button" @click="goBack">Back</b-button>
            <!-- <WordExportButton
                templateName="CBR"
                :templateData="wsData"
                /> -->
          </b-button-group>
        </div>
      </v-col>
    </v-row>

    <v-row id="Company/Year">
      <v-col cols="5">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>Company Name:</b-input-group-text>
          </template>
          <b-form-input
            v-model="wsData.company_name"
            style="background-color: yellow"
          >
          </b-form-input>
        </b-input-group>
      </v-col>
      <v-col cols="5">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>Enter Year:</b-input-group-text>
          </template>
          <b-form-select
            v-model="wsData.work_year"
            style="background-color: yellow"
            :options="list_years"
            @change="getYearlyData"
          >
          </b-form-select>
        </b-input-group>
      </v-col>
    </v-row>

    <v-row id="Cashflow Table">
      <div class="col-md-12" style="margin-top: 20px">
        <v-simple-table style="border-radius: 7px; border: 1px solid #c4c4c4">
          <template>
            <thead style="color: azure">
              <tr>
                <th class="header-black-and-white">ITEM</th>
                <th class="header-black-and-white" style="text-align: right">
                  NUMBERS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  title="Found On the Income Statement (also called P&L) – Also found on TAX
                  returns.  Make sure tax returns and financial statements agree. If there
                  is a difference, prepare an explanation"
                >
                  Start with Net Income (also called Net Profits)
                </td>

                <td style="background-color: yellow">
                  <money v-model="wsData.net_income" v-bind="money"></money>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  title="Found on Income Statement, Tax Return, or Payroll report. If there is more than one owner, you will need to document. It
                  also makes sense to be able to discuss what a “replacement”
                  salary would be for someone else to do the same job."
                >
                  Add Owners Salary + Payroll Taxes
                </td>
                <td style="background-color: yellow">
                  <money v-model="wsData.salary_taxes" v-bind="money"></money>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  title="Found on Income Statement or Tax Return.  Ensure the numbers agree."
                >
                  Add Amortization & Depreciation
                </td>
                <td style="background-color: yellow">
                  <money v-model="wsData.amortization" v-bind="money"></money>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  title="Found on Income Statement or Bank Statements.  Include interest from all business loans, but document loans
                  and amounts."
                >
                  Add Interest Expense
                </td>
                <td style="background-color: yellow">
                  <money v-model="wsData.int_exp" v-bind="money"></money>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  title="Found on Income Statement.  Any one-time expenses which the new owner should not expect.
                  Document and describe."
                >
                  Add Non-Recurring Expenses
                </td>
                <td style="background-color: yellow">
                  <money v-model="wsData.non_recur_exp" v-bind="money"></money>
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">Add Owner Perks</td>
                <td style="background-color: greenyellow">
                  {{ usCurrency.format(this.perks) }}
                  <span style="float: right"
                    ><i class="fa fa-edit" @click="ownerPerks()"></i
                  ></span>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  title="Calculates this total from the inputs above.  Also called Seller’s Discretionary Income (or SDE)"
                >
                  Equals Owner’s Cash Flow
                </td>
                <td style="background-color: greenyellow">
                  {{ usCurrency.format(this.cashFlow) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-row>

    <v-dialog v-model="dialogPerks" max-width="700" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="closeEdit">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>Owner Perks</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>
                      <v-row id="Cashflow Table">
                        <div class="col-md-12" style="margin-top: 20px">
                          <v-simple-table
                            style="
                              border-radius: 7px;
                              border: 1px solid #c4c4c4;
                            "
                          >
                            <template>
                              <thead style="color: azure">
                                <tr>
                                  <th class="header-black-and-white">ITEM</th>

                                  <th
                                    class="header-black-and-white"
                                    style="text-align: right"
                                  >
                                    NUMBERS
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  If the company provides a car that the
                                    company paid cash for (in one year) but does
                                    not have an ongoing monthly payment,
                                    amortize the expense to approximate an
                                    annual number."
                                  >
                                    Car Payment-Owner
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.car_pay_owner"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  Personal, not business use; see comments
                                    above."
                                  >
                                    Car Payment –Spouse or Family Member
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.car_pay_spouse"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  (Note: if you are filling out this Word
                                    Document for Support, you can use this
                                    column for more explanation. The more
                                    support provided the better!)"
                                  >
                                    Car Insurance – Owner
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.car_ins_owner"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  "
                                  >
                                    Car Insurance – Spouse or Family Member
                                  </td>

                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.car_ins_spouse"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries."
                                  >
                                    Health Insurance – Owner
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.health_ins_owner"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  "
                                  >
                                    Health Insurance – Spouse or Family
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.health_ins_spouse"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  "
                                  >
                                    Life Insurance – Owner
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.life_ins_owner"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  "
                                  >
                                    Life Insurance – Spouse or Family
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.life_ins_spouse"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  "
                                  >
                                    401K / IRA / Retirement – Owner or Spouse
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.ira"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>

                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  Personal, ones that will not be required to
                                    maintain for normal business purposes."
                                  >
                                    Charitable Contributions
                                  </td>

                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.charitable_contrib"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  Owner or family for personal purposes – not
                                    business related"
                                  >
                                    Meals & Entertainment
                                  </td>

                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.meals"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  Owner or family for personal purposes – not
                                    business related"
                                  >
                                    Country Club Dues / Memberships
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.memberships"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  Owner or family for personal purposes – not
                                    business related"
                                  >
                                    Travel
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.travel"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>

                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.  Salary + Taxes – if family member does not
                                    work or is overcompensated for role. Prorate
                                    compared to replacement cost if
                                    overcompensated.)"
                                  >
                                    Family Compensation
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.family_comp"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                    title="Found on Income Statement, Invoices, or General
                                    Ledger entries.)"
                                  >
                                    Rent Variance
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.real_rent"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>

                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Other
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.other"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Other
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.other_a"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Other
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.other_b"
                                      v-bind="money"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="
                                      font-weight: 800;
                                      border-right: 1px solid #c4c4c4;
                                    "
                                  >
                                    Total
                                  </td>

                                  <td style="background-color: greenyellow">
                                    {{ usCurrency.format(this.perks) }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div>
                      </v-row>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import { mask } from "vue-the-mask";
import { Money } from "v-money";

export default {
  components: { Money },
  directives: {
    mask,
  },

  created() {
    //this.checkPermissions();
    this.logActivity("Opened", localStorage.getItem("DealID"));

    this.usCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    CommonServices.getItem(this.componentURL)
      .then((res) => {
        console.log("Response: ", res);
        if (res.count == 0) {
          this.getYearlyData();
          this.wsData.real_rent = 0;
        } else {
          console.log("Current Year: ", this.wsData.work_year);
          console.log("Current CBR ID: ", res.results[0].id);
          this.getData(res.results[0].id);
          console.log("Current Year: ", this.wsData.work_year);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL: `Cashflow/?work_year=${
        new Date().getFullYear() - 1
      }&deal_id=${this.$route.params.id}`,
      componentURL_base: `Cashflow`,
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogPerks: false,
      dialogSelectUser: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      file: "",
      profileImageUrl: "",
      currentDeal: localStorage.getItem("Deal"),
      show: false,
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,
      usCurrency: null,

      backDoor: 0,

      workYear: new Date().getFullYear() - 1,
      companyName: "",

      items: [],
      list_years: [
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
      ],

      wsData: {
        id: "",
        deal_id: this.$route.params.id,
        company_name: localStorage.getItem("Deal"),
        work_year: new Date().getFullYear() - 1,
        net_income: 0,
        salary_taxes: 0,
        amortization: 0,
        int_exp: 0,
        non_recur_exp: 0,
        car_pay_owner: 0,
        car_pay_spouse: 0,
        car_ins_owner: 0,
        car_ins_spouse: 0,
        health_ins_owner: 0,
        health_ins_spouse: 0,
        life_ins_owner: 0,
        life_ins_spouse: 0,
        ira: 0,
        charitable_contrib: 0,
        meals: 0,
        memberships: 0,
        travel: 0,
        family_comp: 0,
        other: 0,
        other_a: 0,
        other_b: 0,
        real_rent: 0,
      },

      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: " ",
        precision: 0,
        masked: false,
      },

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "CashFlowCalculator",
        action: "",
        deal_id: "",
        json: "",
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  computed: {
    cashFlow() {
      this.backdoor;
      return (
        this.wsData.net_income +
        this.wsData.salary_taxes +
        this.wsData.amortization +
        this.wsData.int_exp +
        this.wsData.non_recur_exp +
        this.perks
      );
    },

    perks() {
      return (
        this.wsData.car_pay_owner +
        this.wsData.car_pay_spouse +
        this.wsData.car_ins_owner +
        this.wsData.car_ins_spouse +
        this.wsData.health_ins_owner +
        this.wsData.health_ins_spouse +
        this.wsData.life_ins_owner +
        this.wsData.life_ins_spouse +
        this.wsData.ira +
        this.wsData.charitable_contrib +
        this.wsData.meals +
        this.wsData.memberships +
        this.wsData.travel +
        this.wsData.family_comp +
        this.wsData.real_rent +
        this.wsData.other +
        this.wsData.other_a +
        this.wsData.other_b
      );
    },
  },

  methods: {
    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    ownerPerks() {
      this.dialogPerks = true;
    },

    getYearlyData() {
      this.workYear = this.wsData.work_year;
      this.companyName = this.wsData.company_name;
      CommonServices.getList(
        `Cashflow/?work_year=${this.workYear}&deal_id=${this.$route.params.id}`
      )
        .then((res) => {
          console.log("Current Year: ", this.wsData.work_year);
          console.log("Res: ", res);
          if (res.count == 0) {
            this.resetItems();
            //this.wsData = res;
            //this.wsData = { ...this.wsData, ...res };
            console.log("Clean Worksheet", this.wsData);
            this.wsData.company_name = localStorage.getItem("Deal");
            this.wsData.deal_id = this.$route.params.id;
            this.wsData.work_year = this.workYear;

            //this.dialogPerks = true;
            //this.dialogPerks=false;
          } else {
            this.getData(res.results[0].id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeEdit() {
      this.dialogPerks = false;
    },

    resetItems() {
      console.log("Resetting items...");
      this.wsData = {
        id: "",
        deal_id: this.$route.params.id,
        company_name: localStorage.getItem("Deal"),
        net_income: 0,
        salary_taxes: 0,
        amortization: 0,
        int_exp: 0,
        non_recur_exp: 0,
        car_pay_owner: 0,
        car_pay_spouse: 0,
        car_ins_owner: 0,
        car_ins_spouse: 0,
        health_ins_owner: 0,
        health_ins_spouse: 0,
        life_ins_owner: 0,
        life_ins_spouse: 0,
        ira: 0,
        charitable_contrib: 0,
        meals: 0,
        memberships: 0,
        travel: 0,
        family_comp: 0,
        other: 0,
        other_a: 0,
        other_b: 0,
        real_rent: 0,
      };
    },

    saveFormData() {
      console.log("Last updated: ", this.wsData.last_updated);
      console.log("DealID: ", this.wsData.deal_id);
      if (this.wsData.id) {
        CommonServices.updateData(this.componentURL_base, this.wsData)
          .then((response) => {
            const index = this.items.findIndex(
              (item) => item.id === response.id
            );
            this.$set(this.items, index, response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully updated";
            this.logActivity(
              "Saved Form Data",
              this.wsData.deal_id,
              JSON.stringify(this.wsData)
            );
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.response.data).forEach((key) => {
                this.snackText = error.response.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      } else {
        CommonServices.addRecord(this.componentURL_base, this.wsData)
          .then((response) => {
            this.items.push(response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully added";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      }
    },

    getData(ID) {
      console.log("Cashflow Detail Response: ", "test");
      CommonServices.Detail(ID, this.componentURL_base)
        .then((res) => {
          console.log("Cashflow Detail Response: ", res);
          this.wsData = { ...this.wsData, ...res };
          //this.dialogPerks = true;
          this.$forceUpdate();

          //this.dialogPerks=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openTasks() {
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openOffers() {
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openUploads() {
      //First write any missing Deal Folders to the upload table
      CommonServices.putData("Functions", { id: 3 })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
          setTimeout(() => {
            this.$router.push(`/Uploads/${this.$route.params.id}`);
          }, 500);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style type="text/css" src="../css/styles.css"></style>
